<span [class.small]="small" class="button-inner">

  @if (isLoading) {

    <span class="loader-container flex flex-col justify-center items-center">
      <us2-progress-spinner [diameter]="small ? 21 : 34" [strokeWidth]="small ? 4 : 7" [substrateOpacity]="0.5"
                            substrateColor="currentColor"></us2-progress-spinner>
    </span>

  }

  <span [class.flex-row]="iconPosition === 'start'" [class.flex-row-reversed]="iconPosition === 'end'"
        class="button-content flex justify-center items-center gap-x-2">

    @if (hasIcon) {
      <mat-icon [svgIcon]="svgIcon" class="icon">{{ matIcon }}</mat-icon>
    }

    <span><ng-content></ng-content></span>

  </span>

</span>
