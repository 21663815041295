import { coerceBooleanProperty } from '@angular/cdk/coercion';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EnableIfAllowedDirective } from '@bc-core/directives/enable-if-allowed.directive';
import { ProgressSpinnerComponent } from '@shared/ui-components/progress-spinner/progress-spinner.component';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Us2ThemePalette } from '../../models/theme-palette';

@Component({
  selector: 'us2-button, a[us2-button], button[us2-button]',
  standalone: true,
  imports: [
    MatIconModule,
    ProgressSpinnerComponent
],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit, OnDestroy {
  @Input() matIcon: string;
  @Input() svgIcon: string;
  @Input() small: boolean;
  @Input() iconPosition: 'start' | 'end' = 'start';
  @HostBinding('class') themeClass: string;

  private readonly destroyed$ = new Subject<void>();

  @Input()
  @HostBinding('class.stroked')
  get stroked(): boolean { return this._stroked; }

  set stroked(value: string | number | boolean) {
    this._stroked = coerceBooleanProperty(value);
  }

  private _stroked = false;

  @Input()
  @HostBinding('class.large')
  get large(): boolean { return this._large; }

  set large(value: string | number | boolean) {
    this._large = coerceBooleanProperty(value);
  }

  private _large = false;

  get hasIcon(): boolean {
    return !!this.matIcon || !!this.svgIcon;
  }

  @Input()
  @HostBinding('class.loading') isLoading: boolean;

  @Input()
  @HostBinding('class.disabled')
  get disabled(): boolean { return this._disabled; }

  set disabled(value: string | number | boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _disabled = false;

  @Input()
  @HostBinding('class.inverted')
  get inverted(): boolean { return this._inverted; }

  set inverted(value: string | number | boolean) {
    this._inverted = coerceBooleanProperty(value);
  }

  private _inverted = false;

  @Input()
  set color(v: Us2ThemePalette) {
    if (!v) {
      return;
    }

    v = v === 'cancel' ? 'grey' : v;

    this.themeClass = `us2-${v}`;
    this._color = v;
  }

  get color(): Us2ThemePalette {
    return this._color;
  }

  private _color: Us2ThemePalette;

  constructor(
    private cdr: ChangeDetectorRef,
    private el: ElementRef,
    @Optional() @Self() private permissionsDirective: EnableIfAllowedDirective,
  ) {
    this.permissionsDirective?.disabled$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(disabled => {
        this.disabled = disabled;
        this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
    fromEvent(this.el.nativeElement, 'click', { capture: true })
      .pipe(
        filter(() => this.disabled),
        takeUntil(this.destroyed$),
      )
      .subscribe((e: MouseEvent) => {
        e.preventDefault();
        e.stopImmediatePropagation();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener('click', [ '$event' ])
  onClick(event: MouseEvent): void {
    if (this.disabled || this.isLoading) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
